.login1{
    width: 20rem;
    margin: 0 auto;
}
.common-login-container{
    height: 100vh; 
    // height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;

}

.login-logo{
    height: 3rem;
    object-fit: contain;
}
.login-2{    
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: row;  
    .login-img{
        width: 60%;
        background-color: white;
        img {        
                object-fit: contain;
            }
    }
    .login2-form{
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 2rem;   
        align-items: center;     
    }
    .card{
        border-radius: 0;
    }
}
.login-4{
    display: flex;
    flex-direction: row;
    width:34rem;   
    .login-side-logo{
        padding: 2rem;
        width: 50%;
        img{
            object-fit: contain;
        }
    }
    .login-form{
        width: 50%;
        padding: 1rem 0;
    }
}
.unset-pad{
    margin:0 -1.5rem;
}
.google-button{
    width: 100%;
    border:1px solid #b3330e;
    color: #B3330E;   
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    text-align: center !important;
    .icon{
        height: 14px;
    }
}
.captchaBox{
    background-color: #f9f9f9;
    padding: 0.2rem;
    border: 1px solid #b3b3b3;
    border-radius: 2px;
    justify-content: space-between;
    img{
        height: 20px;
        object-fit: contain;
    }
}
.line {
    width: 100%;
    display: flex;
    border-top: 1px solid lightgray;
    justify-content: center;
    padding-bottom: 0.75rem;
    .text {
        margin-top: -0.6rem;
        width: 2rem;
        background: white;
        text-align: center;
    }
}


.login-layout {
    // background: #4ce1b61c;
    background-color: #00e4a21c;
    height: 100vh;
    width: 100vw;
    display: grid;
    
    grid-template-columns: 15vw 35vw 35vw 15vw;
    grid-template-rows: 15vh 70vh 15vh;
    grid-template-areas:
        "header header header header"
        ". image login ."
        "footer footer footer footer";

    .header {
        grid-area: header;
        z-index: 2;
    }

    .footer {
        grid-area: footer;
        z-index: 2;
    }

    .login-box {
        grid-area: login;
        background-color: rgb(255, 255, 255);
        border-radius: 0 10px 10px 0;
        box-shadow: rgb(0 3 61 / 10%) 0px 2px 2px;
        padding: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4rem;
        z-index: 2;
        btn.btn-custom {
            background-color: #15c390 !important;
            color: white;
        }
    }
    .login-img{
        grid-area: image;
        background-color: rgb(255, 255, 255);
        border-radius: 10px 0 0 10px;
        box-shadow: rgb(0 3 61 / 10%) 0px 2px 2px;
        padding: 2rem;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        z-index: 2;
       
    }
    .circle-b{
        position: absolute;
        height: 66.5%;
        width:32.5%;
        border-radius: 50%;
        background:#aaf6e1;
        transform: translate(-100px,32vh);
        z-index: 1;
        overflow: hidden;
    }
    .circle-b2{
        position: absolute;
        height: 26.6%;
        width:13.1%;
        border-radius: 50%;
        background:#aaf6e1;
        transform: translate(78vw,73vh);
        z-index: 1;
        overflow: hidden;
    }
    .circle-t{
        position: absolute;
        height: 13.3%;
        width:6.5%;
        border-radius: 50%;
        background:#aaf6e1;
        transform: translate(88vw,15vh);        
        z-index: 1;
        overflow: hidden;
    }
    .circle-t2{
        position: absolute;
        height: 8%;
        width:3.91%;
        border-radius: 50%;
        background:#aaf6e1;
        transform: translate(95vw,30vh);        
        z-index: 1;
        overflow: hidden;
    }

}
.login-layout2 {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 15vw 1fr 15vw;
    grid-template-rows: 8vh 1fr 15vh;
    grid-template-areas:
        "header2 header2  header2"
        ". login2 ."
        "footer2 footer2 footer2";

    // background: #006849;
    // background: #e5e5e52e;
    .header {
        grid-area: header2;
        background: #4ce1b6;
    }

    .footer {
        grid-area: footer2;

    }

    .login2-sample{
        grid-area: login2;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background: #e5e5e52e;

        .login-card {
            background-color: #4ce1b6;
            border-radius: 10px;
            box-shadow: rgb(0 3 61 / 10%) 0px 2px 2px;            
            padding: 2rem;
        }
    }

}