.sidebar_left-content .custombar1 .p-scrollpanel-content,
.sidebar_left-content .custombar2 .p-scrollpanel-content {
    padding-bottom: 0;
    padding-right: 10px;
}

/* ScrollPanelDemo.css */

/* .sidebar_left-content .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
    border-right: 10px solid #f9fafb;
} */

.sidebar_left-content .p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: #65d2e4;
    opacity: 1;
    transition: background-color .3s;
}

.sidebar_left-content .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
    background-color: #35c4dc;  
}

/* .sidebar_left-content .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
    border-right: 10px solid #f9fafb;
    border-bottom: 10px solid #f9fafb;
} */

.sidebar_left-content .p-scrollpanel.custombar2 .p-scrollpanel-bar {
    background-color: #e5e7eb;
    border-radius: 0;
    opacity: 1;
    transition: background-color .3s;
}

.sidebar_left-content .col-12 {
    padding: 2rem;
}


.p-paginator .p-dropdown.custom-page-dropdown {
    height: 2.5em;
}