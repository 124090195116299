@import 'settings/variable'; //themes' colors

//vendor...
@import './vendor';

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span

//components...
@import './component/btn'; // Button from 'reactstrap'
@import './component/check-box.scss'; // checkbox from 'reactstrap'
@import './component/dropdown'; //
@import './component/form'; // <form> and all types of inputs
@import './component/grid'; // useless. using for grid presentation
@import './component/radio-btn'; // components/form/RadioButton.js
@import './component/react-select';
@import './component/scroll';
@import './component/load';
@import './component/react-select.scss';
@import './component/topbar.scss';
@import './component/date-picker.scss';
@import './component/modal.scss';
@import './component/tabs.scss';
//container 
@import './container/dashboard.scss';
@import './container/account.scss';
@import './component/table.scss';
//objects...
@import 'objects/layout.scss'; // layout settings
@import './custom.scss'; // custom css
@import './table.scss';


@font-face {
    font-family: eduvic;
    src: url("../fonts/EduVICWANTBeginner-Regular.ttf");
}

// body{
//     font-family: eduvic !important;
// }

.p-dropdown {
    // width:100% !important;
}

.p-multiselect-item,
.p-multiselect-label-container {
    label {
        margin-bottom: 0 !important;
    }
}

.p-multiselect-filter-container {
    input {
        margin-bottom: 0 !important;
    }
}

.modal-body {
    max-height: 80vh !important;
    overflow: auto;
}

.screen .modal-body {
    max-height: 100vh !important;
    overflow: unset !important;
}

.p-multiselect-panel,
.p-dropdown-panel {
    z-index: 9999 !important;
}